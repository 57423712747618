import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { Fragment } from "react";
import abousUsImg from "../assets/about-us-light.jpg";
import "./styles.css";

export const AboutUsRs: React.FC = () => {
  return (
    <Fragment>
      {window.innerWidth < 1050 ? (
        <Box className="section-wrapper" id="about-us">
          <Box className="section-container">
            <Box className="section-width">
              <Stack spacing={2}>
                <img src={abousUsImg} style={{ borderRadius: "20px" }} />
                <Box>
                  <Typography
                    className="section-title"
                    sx={{ textAlign: "center", fontSize: "30px !important" }}
                  >
                    <span className="primary-color">О</span> нас
                  </Typography>
                </Box>
                <Box>
                  <p
                    className="section-description"
                    style={{ fontSize: "18px" }}
                  >
                    В Mulla Global Consultancy мы уверены, что каждый
                    предприниматель — это человек с видением. Наша задача —
                    реализовать это видение через экспертные услуги по
                    управлению бизнесом и операционной консультации в ОАЭ. Будь
                    вы местный предприниматель или международный инвестор, мы
                    готовы помочь вам эффективно создать и развить ваш бизнес
                  </p>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className="section-wrapper" id="about-us">
          <Box className="section-container">
            <Box className="section-width">
              <Grid container justifyContent={"space-between"}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Stack spacing={3} className="section-hover-effect">
                    <Box>
                      <Typography className="section-title">
                        <span className="primary-color">О</span> нас
                      </Typography>
                      <Box className="line about-us-line"></Box>
                    </Box>
                    <Box>
                      <p className="section-description">
                        В Mulla Global Consultancy мы уверены, что каждый
                        предприниматель — это человек с видением. Наша задача —
                        реализовать это видение через экспертные услуги по
                        управлению бизнесом и операционной консультации в ОАЭ.
                        Будь вы местный предприниматель или международный
                        инвестор, мы готовы помочь вам эффективно создать и
                        развить ваш бизнес
                      </p>
                    </Box>
                  </Stack>
                </Grid>
                <Grid
                  item
                  alignSelf={"center"}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                  className="section-img-container"
                >
                  <img src={abousUsImg} className="section-img" />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      )}
    </Fragment>
  );
};
