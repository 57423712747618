import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { Fragment } from "react";
import footerBg from "../assets/footer.png";
import "./styles.css";
import animationData from "../assets/lottie/email2.json";
import Lottie from "react-lottie";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import { HoverableIconedView } from "../view/hoverable.point.view";

export const ContactUsForm: React.FC = () => {
  return (
    <Fragment>
      {window.innerWidth > 1050 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#484da1",
            position: "relative",
          }}
        >
          {" "}
          <Box
            sx={{
              // border: "1px solid black",
              height: "72vh",
              width: "85vw",
              position: "absolute",
              top: "9vh",
              left: "7vw",
              zIndex: "100",
            }}
            className="contact-us-form-container"
          >
            <Grid container justifyContent={"space-between"}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Grid container justifyContent={"center"}>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Box className="section-hover-effect">
                      <Typography className="section-title">
                        <span className="primary-color">G</span>et in touch with
                        us
                      </Typography>
                      <Box className="line contact-us-line"></Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      paddingLeft: "3vw",
                      paddingTop: "3vh",
                    }}
                  >
                    <Stack spacing={3} justifyContent={"center"}>
                      <HoverableIconedView
                        value="+971 54 79 76 707"
                        title="WhatsApp"
                        buttonTitle="Chat"
                        onClick={() => {
                          window.open("https://wa.me/971547976707", "_blank");
                        }}
                        iconComponent={
                          <Box>
                            <WhatsAppIcon
                              className="contact-us-icon"
                              style={{ fontSize: "2vw", color: "white" }}
                            />
                          </Box>
                        }
                      />

                      <HoverableIconedView
                        value="Almulla@mullagroup.ae"
                        title="Email"
                        buttonTitle="Contact"
                        onClick={() => {
                          const mailtoLink = "mailto:Almulla@mullagroup.ae";

                          window.location.href = mailtoLink;
                        }}
                        iconComponent={
                          <Box>
                            <EmailIcon
                              className="contact-us-icon"
                              style={{ fontSize: "2vw", color: "white" }}
                            />
                          </Box>
                        }
                      />

                      <HoverableIconedView
                        value="mulla.consultancy"
                        title="Instagram"
                        buttonTitle="Discover"
                        onClick={() => {
                          window.open(
                            "https://www.instagram.com/mulla.consultancy",
                            "_blank"
                          );
                        }}
                        iconComponent={
                          <Box>
                            <InstagramIcon
                              className="contact-us-icon"
                              style={{ fontSize: "2vw", color: "white" }}
                            />
                          </Box>
                        }
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="hidden-on-small"
              >
                <Grid container justifyContent={"center"}>
                  <Grid item>
                    <Lottie
                      options={{
                        animationData: animationData,
                        loop: true,
                        autoplay: true,
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice",
                        },
                      }}
                      // width={500}
                      height={600}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <img
            src={footerBg}
            className="main-view-img"
            style={{ height: "90vh", width: "auto !important" }}
            id="contact-us"
          />
        </Box>
      ) : window.innerWidth > 650 && window.innerWidth < 1050 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#484da1",
            position: "relative",
          }}
        >
          {" "}
          <Box
            sx={{
              // border: "1px solid black",
              height: "72vh",
              width: "85vw",
              position: "absolute",
              top: "9vh",
              left: "7vw",
              zIndex: "100",
            }}
            className="contact-us-form-container"
          >
            <Stack spacing={3}>
              <Box className="section-hover-effect">
                <Typography
                  className="section-title"
                  sx={{ textAlign: "center !important" }}
                >
                  <span className="primary-color">G</span>et in touch with us
                </Typography>
              </Box>

              <Box>
                <Grid container justifyContent={"center"}>
                  <Grid item>
                    <Lottie
                      options={{
                        animationData: animationData,
                        loop: true,
                        autoplay: true,
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice",
                        },
                      }}
                      // width={500}
                      height={300}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Stack spacing={3} justifyContent={"center"} direction={"row"}>
                  <HoverableIconedView
                    value="+971 54 79 76 707"
                    title="WhatsApp"
                    buttonTitle="Chat"
                    onClick={() => {
                      window.open("https://wa.me/971547976707", "_blank");
                    }}
                    iconComponent={
                      <Box>
                        <WhatsAppIcon
                          className="contact-us-icon"
                          style={{ fontSize: "2vw", color: "white" }}
                        />
                      </Box>
                    }
                  />

                  <HoverableIconedView
                    value="Almulla@mullagroup.ae"
                    title="Email"
                    buttonTitle="Contact"
                    onClick={() => {
                      const mailtoLink = "mailto:Almulla@mullagroup.ae";

                      window.location.href = mailtoLink;
                    }}
                    iconComponent={
                      <Box>
                        <EmailIcon
                          className="contact-us-icon"
                          style={{ fontSize: "2vw", color: "white" }}
                        />
                      </Box>
                    }
                  />

                  <HoverableIconedView
                    value="mulla.consultancy"
                    title="Instagram"
                    buttonTitle="Discover"
                    onClick={() => {
                      window.open(
                        "https://www.instagram.com/mulla.consultancy",
                        "_blank"
                      );
                    }}
                    iconComponent={
                      <Box>
                        <InstagramIcon
                          className="contact-us-icon"
                          style={{ fontSize: "2vw", color: "white" }}
                        />
                      </Box>
                    }
                  />
                </Stack>
              </Box>
            </Stack>
          </Box>
          <img
            src={footerBg}
            className="main-view-img"
            style={{ height: "90vh", width: "auto !important" }}
            id="contact-us"
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#484da1",
            position: "relative",
          }}
        >
          {" "}
          <Box
            sx={{
              // border: "1px solid black",
              height: "72vh",
              width: "85vw",
              position: "absolute",
              top: "9vh",
              left: "7vw",
              zIndex: "100",
            }}
            className="contact-us-form-container"
          >
            <Grid container justifyContent={"space-between"}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Grid container justifyContent={"center"}>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Box className="section-hover-effect">
                      <Typography className="section-title">
                        <span className="primary-color">G</span>et in touch with
                        us
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      paddingLeft: "3vw",
                      paddingTop: "3vh",
                    }}
                  >
                    {window.innerWidth > 650 ? (
                      <Stack spacing={3} justifyContent={"center"}>
                        <HoverableIconedView
                          value="+971 54 79 76 707"
                          title="WhatsApp"
                          buttonTitle="Chat"
                          onClick={() => {
                            window.open("https://wa.me/971547976707", "_blank");
                          }}
                          iconComponent={
                            <Box>
                              <WhatsAppIcon
                                className="contact-us-icon"
                                style={{ fontSize: "2vw", color: "white" }}
                              />
                            </Box>
                          }
                        />

                        <HoverableIconedView
                          value="Almulla@mullagroup.ae"
                          title="Email"
                          buttonTitle="Contact"
                          onClick={() => {
                            const mailtoLink = "mailto:Almulla@mullagroup.ae";

                            window.location.href = mailtoLink;
                          }}
                          iconComponent={
                            <Box>
                              <EmailIcon
                                className="contact-us-icon"
                                style={{ fontSize: "2vw", color: "white" }}
                              />
                            </Box>
                          }
                        />

                        <HoverableIconedView
                          value="mulla.consultancy"
                          title="Instagram"
                          buttonTitle="Discover"
                          onClick={() => {
                            window.open(
                              "https://www.instagram.com/mulla.consultancy",
                              "_blank"
                            );
                          }}
                          iconComponent={
                            <Box>
                              <InstagramIcon
                                className="contact-us-icon"
                                style={{ fontSize: "2vw", color: "white" }}
                              />
                            </Box>
                          }
                        />
                      </Stack>
                    ) : (
                      <Grid container spacing={1}>
                        <Grid item sm={6} xs={6}>
                          <HoverableIconedView
                            value="+971 54 79 76 707"
                            title="WhatsApp"
                            buttonTitle="Chat"
                            onClick={() => {
                              window.open(
                                "https://wa.me/971547976707",
                                "_blank"
                              );
                            }}
                            iconComponent={
                              <Box>
                                <WhatsAppIcon
                                  className="contact-us-icon"
                                  style={{ fontSize: "2vw", color: "white" }}
                                />
                              </Box>
                            }
                          />
                        </Grid>

                        <Grid item sm={6} xs={6}>
                          <HoverableIconedView
                            value="mulla.consultancy"
                            title="Instagram"
                            buttonTitle="Discover"
                            onClick={() => {
                              window.open(
                                "https://www.instagram.com/mulla.consultancy",
                                "_blank"
                              );
                            }}
                            iconComponent={
                              <Box>
                                <InstagramIcon
                                  className="contact-us-icon"
                                  style={{ fontSize: "2vw", color: "white" }}
                                />
                              </Box>
                            }
                          />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <HoverableIconedView
                            value="Almulla@mullagroup.ae"
                            title="Email"
                            buttonTitle="Contact"
                            onClick={() => {
                              const mailtoLink = "mailto:Almulla@mullagroup.ae";

                              window.location.href = mailtoLink;
                            }}
                            iconComponent={
                              <Box>
                                <EmailIcon
                                  className="contact-us-icon"
                                  style={{ fontSize: "2vw", color: "white" }}
                                />
                              </Box>
                            }
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {window.innerWidth > 650 ? (
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="hidden-on-small"
                >
                  <Grid container justifyContent={"center"}>
                    <Grid item>
                      <Lottie
                        options={{
                          animationData: animationData,
                          loop: true,
                          autoplay: true,
                          rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                          },
                        }}
                        // width={500}
                        height={600}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Box>
          <img
            src={footerBg}
            className="main-view-img"
            style={{ height: "90vh", width: "auto !important" }}
            id="contact-us"
          />
        </Box>
      )}
    </Fragment>
  );
};
