import {
  Box,
  Chip,
  Grid,
  Icon,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { Fragment } from "react";
import "../components/styles.css";
interface IProps {
  title: string;
  value: string;
  iconComponent: React.ReactNode;
  buttonTitle: string;
  onClick: () => void;
}

export const HoverableIconedView: React.FC<IProps> = ({
  title,
  value,
  iconComponent,
  buttonTitle,
  onClick,
}) => {
  return (
    <Fragment>
      {window.innerWidth > 1050 ? (
        <Paper
          elevation={5}
          className="contact-card-container"
          sx={{ padding: "1vh" }}
        >
          <Grid
            container
            sx={{ height: "15vh" }}
            spacing={window.innerWidth > 650 ? 2 : 0}
            className="contact-card-grid-container"
            justifyContent={"space-between"}
          >
            {window.innerWidth > 650 ? (
              <Grid
                item
                lg={window.innerWidth > 650 ? 2 : 8}
                md={window.innerWidth > 650 ? 3 : 8}
                sm={window.innerWidth > 650 ? 3 : 8}
                xs={window.innerWidth > 650 ? 3 : 8}
                alignSelf={"center"}
              >
                <Box
                  sx={{
                    backgroundColor: "#484da1",
                    borderRadius: "0.7vw",
                    padding: "0.8vw",
                    maxWidth: "fit-content",
                  }}
                  className="contact-us-icon-container"
                >
                  {iconComponent}
                </Box>
              </Grid>
            ) : null}

            <Grid lg={6} md={5} sm={7} xs={7} item alignSelf={"center"}>
              <Stack>
                <p className="contact-card-title">{title}</p>
                <p className="contact-card-text">{value}</p>
              </Stack>
            </Grid>
            <Grid
              lg={window.innerWidth > 650 ? 4 : undefined}
              md={window.innerWidth > 650 ? 4 : undefined}
              sm={window.innerWidth > 650 ? 5 : undefined}
              xs={window.innerWidth > 650 ? 5 : undefined}
              item
              alignSelf={"center"}
            >
              <Grid container justifyContent={"flex-end"}>
                <Grid item>
                  {window.innerWidth > 650 ? (
                    <Chip
                      label={
                        <p
                          style={{
                            fontSize: "2vh",
                            fontWeight: "600",
                            color: "white",
                          }}
                          className="send-btn-title"
                        >
                          {buttonTitle}
                        </p>
                      }
                      icon={
                        <Icon
                          style={{
                            color: "white",
                            fontSize: "2vh",
                          }}
                        >
                          send
                        </Icon>
                      }
                      sx={{
                        backgroundColor: "#484da1",
                        borderRadius: "0.7vw",
                        padding: "0.5vh",
                        mr: "1vw",
                      }}
                      className="hoverable-btn"
                      onClick={onClick}
                    />
                  ) : (
                    <IconButton
                      sx={{
                        backgroundColor: "#484da1",
                        borderRadius: "0.7vw",
                        padding: "0.5vh",
                        mr: "1vw",
                      }}
                      className="hoverable-btn"
                      onClick={onClick}
                    >
                      <Icon
                        style={{
                          color: "white",
                          fontSize: "2vh",
                        }}
                      >
                        send
                      </Icon>
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      ) : window.innerWidth < 1050 && window.innerWidth > 650 ? (
        <Paper
          elevation={5}
          className="contact-card-container"
          sx={{ padding: "1vh", borderRadius: "15px" }}
        >
          <Stack spacing={3} direction={"row"}>
            <Box sx={{ alignContent: "center" }}>
              <Box
                sx={{
                  backgroundColor: "#484da1",
                  borderRadius: "10px",
                  padding: "5px",
                  alignContent: "center",
                }}
              >
                {iconComponent}
              </Box>
            </Box>
            <Box>
              <Stack spacing={1}>
                <Typography
                  sx={{ fontSize: "15px", color: "grey", fontWeight: "600" }}
                >
                  {title}
                </Typography>
                <Typography
                  sx={{ fontSize: "18px", color: "black", fontWeight: "700" }}
                >
                  {value}
                </Typography>
                <Chip
                  label={
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      {buttonTitle}
                    </p>
                  }
                  icon={
                    <Icon
                      style={{
                        color: "white",
                        fontSize: "2vh",
                      }}
                    >
                      send
                    </Icon>
                  }
                  sx={{
                    backgroundColor: "#484da1",
                    borderRadius: "0.7vw",
                    padding: "0.5vh",
                    mr: "1vw",
                  }}
                  className="hoverable-btn"
                  onClick={onClick}
                />
              </Stack>
            </Box>
          </Stack>
        </Paper>
      ) : (
        <Paper
          elevation={5}
          className="contact-card-container"
          sx={{ padding: "1vh" }}
        >
          <Grid
            container
            sx={{ height: "15vh" }}
            spacing={window.innerWidth > 650 ? 2 : 0}
            className="contact-card-grid-container"
            justifyContent={"space-between"}
          >
            {window.innerWidth > 650 ? (
              <Grid
                item
                lg={window.innerWidth > 650 ? 2 : 8}
                md={window.innerWidth > 650 ? 3 : 8}
                sm={window.innerWidth > 650 ? 3 : 8}
                xs={window.innerWidth > 650 ? 3 : 8}
                alignSelf={"center"}
              >
                <Box
                  sx={{
                    backgroundColor: "#484da1",
                    borderRadius: "0.7vw",
                    padding: "0.8vw",
                    maxWidth: "fit-content",
                  }}
                  className="contact-us-icon-container"
                >
                  {iconComponent}
                </Box>
              </Grid>
            ) : null}

            <Grid lg={6} md={5} sm={7} xs={7} item alignSelf={"center"}>
              <Stack>
                <p className="contact-card-title">{title}</p>
                <p className="contact-card-text">{value}</p>
              </Stack>
            </Grid>
            <Grid
              lg={window.innerWidth > 650 ? 4 : undefined}
              md={window.innerWidth > 650 ? 4 : undefined}
              sm={window.innerWidth > 650 ? 5 : undefined}
              xs={window.innerWidth > 650 ? 5 : undefined}
              item
              alignSelf={"center"}
            >
              <Grid container justifyContent={"flex-end"}>
                <Grid item>
                  {window.innerWidth > 650 ? (
                    <Chip
                      label={
                        <p
                          style={{
                            fontSize: "2vh",
                            fontWeight: "600",
                            color: "white",
                          }}
                          className="send-btn-title"
                        >
                          {buttonTitle}
                        </p>
                      }
                      icon={
                        <Icon
                          style={{
                            color: "white",
                            fontSize: "2vh",
                          }}
                        >
                          send
                        </Icon>
                      }
                      sx={{
                        backgroundColor: "#484da1",
                        borderRadius: "0.7vw",
                        padding: "0.5vh",
                        mr: "1vw",
                      }}
                      className="hoverable-btn"
                      onClick={onClick}
                    />
                  ) : (
                    <IconButton
                      sx={{
                        backgroundColor: "#484da1",
                        borderRadius: "0.7vw",
                        padding: "0.5vh",
                        mr: "1vw",
                      }}
                      className="hoverable-btn"
                      onClick={onClick}
                    >
                      <Icon
                        style={{
                          color: "white",
                          fontSize: "2vh",
                        }}
                      >
                        send
                      </Icon>
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Fragment>
  );
};
