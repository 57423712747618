import { Box, Grid, Icon, Stack, Typography } from "@mui/material";
import React, { Fragment } from "react";
import howWeWorkImg from "../assets/how-we-work-light.jpg";
import "./styles.css";
import { IconedView } from "../view/point.view";

export const HowWeWork: React.FC = () => {
  return (
    <Fragment>
      {window.innerWidth < 1050 ? (
        <Box className="section-wrapper" id="how-we-work">
          <Box className="section-container">
            <Box className="section-width">
              <Stack spacing={2}>
                <img src={howWeWorkImg} style={{ borderRadius: "20px" }} />

                <Box>
                  <Typography
                    className="section-title"
                    sx={{ textAlign: "center", fontSize: "30px !important" }}
                  >
                    <span className="primary-color">H</span>ow We Work
                  </Typography>
                </Box>
                <Box>
                  <Stack direction={"row"} spacing={1}>
                    <Icon
                      className="primary-color"
                      style={{
                        alignSelf: "center",
                        fontSize: "12px",
                      }}
                    >
                      circle
                    </Icon>
                    <Typography
                      className="primary-color "
                      style={{ fontSize: "20px ", fontWeight: "600" }}
                    >
                      Project Client
                    </Typography>
                  </Stack>
                  <p
                    className="section-description"
                    style={{ paddingLeft: "1.5vw" }}
                  >
                    Our project clients will go through the following steps:
                    <Box sx={{ paddingTop: "1vh !important" }}>
                      <IconedView
                        icon="mark_email_read"
                        title="Submit your query"
                      />
                      <IconedView
                        icon="calendar_month"
                        title="Schedule an interview and assessment"
                      />
                      <IconedView
                        icon="document_scanner"
                        title="Receive your initial report"
                      />
                      <IconedView
                        icon="menu_book"
                        title="Receive your Project Handbook"
                      />
                      <IconedView
                        icon="auto_awesome"
                        title="Implement your handbook and witness your business vision realized"
                      />
                    </Box>
                  </p>

                  <Stack
                    direction={"row"}
                    spacing={1}
                    sx={{ paddingTop: "2vh" }}
                  >
                    <Icon
                      className="primary-color"
                      style={{
                        alignSelf: "center",
                        fontSize: "12px",
                      }}
                    >
                      circle
                    </Icon>
                    <Typography
                      className="primary-color"
                      style={{ fontSize: "20px ", fontWeight: "600" }}
                    >
                      Targeted Consultations
                    </Typography>
                  </Stack>
                  <p
                    className="section-description"
                    style={{ paddingLeft: "1.5vw" }}
                  >
                    We are also happy to assist clients wishing to engage with
                    us on specific aspects. Please contact us for more details.
                  </p>
                </Box>
                <Box>
                  <p className="section-description">
                    For more details please find the guide{" "}
                    <a
                      href="/assets/Mulla.pdf"
                      download="Mulla Consultancy Group - Guide.pdf"
                      className="download-btn"
                    >
                      here
                    </a>
                    ?
                  </p>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className="section-wrapper" id="how-we-work">
          <Box className="section-container">
            <Box className="section-width">
              <Grid container justifyContent={"space-between"}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Stack spacing={3} className="section-hover-effect">
                    <Box>
                      <Typography className="section-title">
                        <span className="primary-color">H</span>ow We Work
                      </Typography>
                      <Box className="line how-we-work-line"></Box>
                    </Box>
                    <Box>
                      <Stack direction={"row"} spacing={1}>
                        <Icon
                          className="primary-color"
                          style={{
                            alignSelf: "center",
                            fontSize: "0.8vw",
                            marginBottom: "-0.2vh",
                          }}
                        >
                          circle
                        </Icon>
                        <p className="primary-color how-we-work-title">
                          Project Client
                        </p>
                      </Stack>
                      <p
                        className="section-description"
                        style={{ paddingLeft: "1.5vw" }}
                      >
                        Our project clients will go through the following steps:
                        <Box sx={{ paddingTop: "1vh !important" }}>
                          <IconedView
                            icon="mark_email_read"
                            title="Submit your query"
                          />
                          <IconedView
                            icon="calendar_month"
                            title="Schedule an interview and assessment"
                          />
                          <IconedView
                            icon="document_scanner"
                            title="Receive your initial report"
                          />
                          <IconedView
                            icon="menu_book"
                            title="Receive your Project Handbook"
                          />
                          <IconedView
                            icon="auto_awesome"
                            title="Implement your handbook and witness your business vision realized"
                          />
                        </Box>
                      </p>

                      <Stack
                        direction={"row"}
                        spacing={1}
                        sx={{ paddingTop: "2vh" }}
                      >
                        <Icon
                          className="primary-color"
                          style={{
                            alignSelf: "center",
                            fontSize: "0.8vw",
                            marginBottom: "-0.2vh",
                          }}
                        >
                          circle
                        </Icon>
                        <p className="primary-color how-we-work-title">
                          Targeted Consultations
                        </p>
                      </Stack>
                      <p
                        className="section-description"
                        style={{ paddingLeft: "1.5vw" }}
                      >
                        We are also happy to assist clients wishing to engage
                        with us on specific aspects. Please contact us for more
                        details.
                      </p>
                    </Box>
                    <Box>
                      <p className="section-description">
                        For more details please find the guide{" "}
                        <a
                          href="/assets/Mulla.pdf"
                          download="Mulla Consultancy Group - Guide.pdf"
                          className="download-btn"
                        >
                          here
                        </a>
                        ?
                      </p>
                    </Box>
                  </Stack>
                </Grid>
                <Grid
                  item
                  alignSelf={"center"}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                  className="section-img-container"
                >
                  <img src={howWeWorkImg} className="section-img" />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      )}
    </Fragment>
  );
};
