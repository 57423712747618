import React, { Fragment } from "react";
import mainImg from "../assets/main-bg-light.jpg";
import { Box, Typography } from "@mui/material";
import "./styles.css";
export const MainView: React.FC = () => {
  return (
    <Fragment>
      {window.innerWidth < 450 ? (
        <Box className="main-view-container">
          <Box
            sx={{
              position: "absolute",
              left: "18px",
              top: "200px",
              zIndex: 20,
            }}
          >
            <Typography
              sx={{ fontSize: "45px", fontWeight: "900", color: "white" }}
            >
              You Envision it
            </Typography>
            <Typography
              sx={{ fontSize: "45px", fontWeight: "900", color: "white" }}
            >
              & We Execute it
            </Typography>
          </Box>
          <img
            src={mainImg}
            style={{
              width: "100%",
              zIndex: 20,
              userSelect: "none",
              height: "400px",
              objectFit: "cover",
            }}
          />
        </Box>
      ) : (
        <Box className="main-view-container">
          <Box className="main-view-title">
            <Typography className="main-view-title-text">
              You Envision it
            </Typography>
            <Typography className="main-view-title-text">
              & We Execute it
            </Typography>
          </Box>
          <img src={mainImg} className="main-view-img" />
        </Box>
      )}
    </Fragment>
  );
};
