import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { Fragment } from "react";
import "./styles.css";
import ourMissionImg from "../assets/our-mission-light.jpg";

export const OurMission: React.FC = () => {
  return (
    <Fragment>
      {window.innerWidth < 1050 ? (
        <Box className="section-wrapper" id="our-mission">
          <Box className="section-container">
            <Box className="section-width">
              <Stack spacing={2}>
                <img src={ourMissionImg} style={{ borderRadius: "20px" }} />
                <Box>
                  <Typography
                    className="section-title"
                    sx={{ textAlign: "center", fontSize: "30px !important" }}
                  >
                    <span className="primary-color">O</span>ur Mission
                  </Typography>
                </Box>
                <Box>
                  <p
                    className="section-description"
                    style={{ fontSize: "18px" }}
                  >
                    <strong> You envision it, and we execute it. </strong>We
                    ensure that our clients experience seamless business setups,
                    regulatory compliance, effective talent acquisition,
                    streamlined operations, and sustainable growth.
                  </p>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className="section-wrapper" id="our-mission">
          <Box className="section-container">
            <Box className="section-width">
              <Grid container justifyContent={"space-between"}>
                <Grid
                  item
                  alignSelf={"center"}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                  className="section-img-container"
                >
                  <img src={ourMissionImg} className="section-img" />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Stack spacing={3} className="section-hover-effect">
                    <Box>
                      <Typography className="section-title">
                        <span className="primary-color">O</span>ur Mission
                      </Typography>
                      <Box className="line our-mission-line"></Box>
                    </Box>
                    <Box>
                      <p className="section-description">
                        <strong> You envision it, and we execute it. </strong>We
                        ensure that our clients experience seamless business
                        setups, regulatory compliance, effective talent
                        acquisition, streamlined operations, and sustainable
                        growth.
                      </p>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      )}
    </Fragment>
  );
};
