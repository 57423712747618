import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { Fragment, useEffect, useRef } from "react";
import "./styles.css";
import horizontalLogo from "../assets/horiz-logo.png";

export const Header: React.FC = () => {
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (window.innerWidth > 450) {
      const header = headerRef.current;
      if (!header) return;

      const sticky = header.offsetTop;

      const handleScroll = () => {
        if (window.scrollY - 50 > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      const header = headerRef.current;
      if (!header) return;
      header.classList.add("sticky");
    }
  }, []);

  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Fragment>
      {window.innerWidth < 450 ? (
        <Box ref={headerRef} className="header" id="myHeader">
          <Stack spacing={1} sx={{ mb: "10px" }}>
            <Grid container justifyContent={"center"}>
              <Grid item>
                <img
                  src={horizontalLogo}
                  className="header-logo"
                  style={{ marginTop: "20px" }}
                />
              </Grid>
            </Grid>
            <Box>
              <Grid container spacing={1} justifyContent={"space-around"}>
                <Grid
                  item
                  lg={2.5}
                  md={2.5}
                  sm={2.5}
                  xs={2.5}
                  alignSelf={"center"}
                >
                  <Box
                    className="primary-color"
                    onClick={() => scrollToSection("about-us")}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      About us
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  lg={2.9}
                  md={2.9}
                  sm={2.9}
                  xs={2.9}
                  alignSelf={"center"}
                >
                  <Box
                    className="primary-color"
                    onClick={() => scrollToSection("our-mission")}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      Our Mission
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  lg={3.2}
                  md={3.2}
                  sm={3.2}
                  xs={3.2}
                  alignSelf={"center"}
                >
                  <Box
                    className="primary-color"
                    onClick={() => scrollToSection("how-we-work")}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      How We Work
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  lg={2.89}
                  md={2.89}
                  sm={2.89}
                  xs={2.89}
                  alignSelf={"center"}
                >
                  <Box
                    className="primary-color"
                    onClick={() => scrollToSection("contact-us")}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      Contact Us
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Box>
      ) : (
        <Box ref={headerRef} className="header" id="myHeader">
          <Grid container justifyContent={"space-between"} sx={{ padding: 3 }}>
            <Grid item alignSelf={"center"}>
              <img src={horizontalLogo} className="header-logo" />
            </Grid>
            <Grid item alignSelf={"center"}>
              <Grid container spacing={4} justifyContent={"flex-end"}>
                <Grid item alignSelf={"center"}>
                  <Box
                    className="header-btn primary-color"
                    onClick={() => scrollToSection("about-us")}
                  >
                    About us
                  </Box>
                </Grid>
                <Grid item alignSelf={"center"}>
                  <Box
                    className="header-btn primary-color"
                    onClick={() => scrollToSection("our-mission")}
                  >
                    Our Mission
                  </Box>
                </Grid>
                <Grid item alignSelf={"center"}>
                  <Box
                    className="header-btn primary-color"
                    onClick={() => scrollToSection("how-we-work")}
                  >
                    How We Work
                  </Box>
                </Grid>
                <Grid item alignSelf={"center"}>
                  <Box
                    className="header-btn primary-color"
                    onClick={() => scrollToSection("contact-us")}
                  >
                    Contact Us
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </Fragment>
  );
};
