import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { Fragment } from "react";
import abousUsImg from "../assets/about-us-light.jpg";
import "./styles.css";

export const AboutUsAr: React.FC = () => {
  return (
    <Fragment>
      {window.innerWidth < 1050 ? (
        <Box className="section-wrapper" id="about-us">
          <Box className="section-container">
            <Box className="section-width">
              <Stack spacing={2}>
                <img src={abousUsImg} style={{ borderRadius: "20px" }} />
                <Box>
                  <Typography
                    className="section-title"
                    sx={{ textAlign: "center", fontSize: "30px !important" }}
                  >
                    <span className="primary-color">ع</span>نا
                  </Typography>
                </Box>
                <Box>
                  <p
                    className="section-description"
                    style={{ fontSize: "18px" }}
                  >
                    نؤمن في شركة الملا العالمية للاستشارات، بأن كل رائد أعمال
                    لديه رؤية. يتمثل دورنا في تجسيد هذه الرؤية من خلال خدمات
                    الاستشارات الإدارية والتشغيلية في دولة الإمارات العربية
                    المتحدة. سواءً كنت رائد أعمال محلي أو مستثمرًا دوليًا، فنحن
                    هنا لمساعدتك في تأسيس وتنمية أعمالك من الفكرة إلى التشغيل.
                  </p>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className="section-wrapper" id="about-us">
          <Box className="section-container">
            <Box className="section-width">
              <Grid container justifyContent={"space-between"}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Stack spacing={3} className="section-hover-effect">
                    <Box>
                      <Typography className="section-title">
                        <span className="primary-color">ع</span>نا
                      </Typography>
                      <Box className="line about-us-line"></Box>
                    </Box>
                    <Box>
                      <p className="section-description">
                        نؤمن في شركة الملا العالمية للاستشارات، بأن كل رائد
                        أعمال لديه رؤية. يتمثل دورنا في تجسيد هذه الرؤية من خلال
                        خدمات الاستشارات الإدارية والتشغيلية في دولة الإمارات
                        العربية المتحدة. سواءً كنت رائد أعمال محلي أو مستثمرًا
                        دوليًا، فنحن هنا لمساعدتك في تأسيس وتنمية أعمالك من
                        الفكرة إلى التشغيل.
                      </p>
                    </Box>
                  </Stack>
                </Grid>
                <Grid
                  item
                  alignSelf={"center"}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                  className="section-img-container"
                >
                  <img src={abousUsImg} className="section-img" />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      )}
    </Fragment>
  );
};
