import { Box, Grid, Icon, Stack, Typography } from "@mui/material";
import React, { Fragment } from "react";
import howWeWorkImg from "../assets/how-we-work-light.jpg";
import "./styles.css";
import { IconedView } from "../view/point.view";

export const HowWeWorkRs: React.FC = () => {
  return (
    <Fragment>
      {window.innerWidth < 1050 ? (
        <Box className="section-wrapper" id="how-we-work">
          <Box className="section-container">
            <Box className="section-width">
              <Stack spacing={2}>
                <img src={howWeWorkImg} style={{ borderRadius: "20px" }} />

                <Box>
                  <Typography
                    className="section-title"
                    sx={{ textAlign: "center", fontSize: "30px !important" }}
                  >
                    <span className="primary-color">К</span>ак Мы Работаем
                  </Typography>
                </Box>
                <Box>
                  <Stack direction={"row"} spacing={1}>
                    <Icon
                      className="primary-color"
                      style={{
                        alignSelf: "center",
                        fontSize: "12px",
                      }}
                    >
                      circle
                    </Icon>
                    <Typography
                      className="primary-color "
                      style={{ fontSize: "20px ", fontWeight: "600" }}
                    >
                      Project Client
                    </Typography>
                  </Stack>
                  <p
                    className="section-description"
                    style={{ paddingLeft: "1.5vw" }}
                  >
                    Наши клиенты по проектам будут следовать следующим шагам:
                    <Box sx={{ paddingTop: "1vh !important" }}>
                      <IconedView
                        icon="mark_email_read"
                        title="Отправьте ваш запрос"
                      />
                      <IconedView
                        icon="calendar_month"
                        title="Запланируйте собеседование и оценку"
                      />
                      <IconedView
                        icon="document_scanner"
                        title="Получите ваш первоначальный отчет"
                      />
                      <IconedView
                        icon="menu_book"
                        title="Получите Информационный пакет по проекту"
                      />
                      <IconedView
                        icon="auto_awesome"
                        title="Примените ваше пособие и наблюдайте, как ваше бизнес-видение воплощается в жизнь"
                      />
                    </Box>
                  </p>

                  <Stack
                    direction={"row"}
                    spacing={1}
                    sx={{ paddingTop: "2vh" }}
                  >
                    <Icon
                      className="primary-color"
                      style={{
                        alignSelf: "center",
                        fontSize: "12px",
                      }}
                    >
                      circle
                    </Icon>
                    <Typography
                      className="primary-color"
                      style={{ fontSize: "20px ", fontWeight: "600" }}
                    >
                      Целевые консультации
                    </Typography>
                  </Stack>
                  <p
                    className="section-description"
                    style={{ paddingLeft: "1.5vw" }}
                  >
                    Мы также готовы помочь клиентам, заинтересованным в
                    консультациях по отдельным вопросам. Пожалуйста, свяжитесь с
                    нами для получения дополнительной информации.
                  </p>
                </Box>
                <Box>
                  <p className="section-description">
                    Для получения дополнительных подробностей, пожалуйста,
                    ознакомьтесь с руководством по{" "}
                    <a
                      href="/assets/Mulla.pdf"
                      download="Mulla Consultancy Group - Guide.pdf"
                      className="download-btn"
                    >
                      ссылке
                    </a>
                  </p>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className="section-wrapper" id="how-we-work">
          <Box className="section-container">
            <Box className="section-width">
              <Grid container justifyContent={"space-between"}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Stack spacing={3} className="section-hover-effect">
                    <Box>
                      <Typography className="section-title">
                        <span className="primary-color">К</span>ак Мы Работаем
                      </Typography>
                      <Box className="line how-we-work-line"></Box>
                    </Box>
                    <Box>
                      <Stack direction={"row"} spacing={1}>
                        <Icon
                          className="primary-color"
                          style={{
                            alignSelf: "center",
                            fontSize: "0.8vw",
                            marginBottom: "-0.2vh",
                          }}
                        >
                          circle
                        </Icon>
                        <p className="primary-color how-we-work-title">
                          Project Client
                        </p>
                      </Stack>
                      <p
                        className="section-description"
                        style={{ paddingLeft: "1.5vw" }}
                      >
                        Наши клиенты по проектам будут следовать следующим
                        шагам:
                        <Box sx={{ paddingTop: "1vh !important" }}>
                          <IconedView
                            icon="mark_email_read"
                            title="Отправьте ваш запрос"
                          />
                          <IconedView
                            icon="calendar_month"
                            title="Запланируйте собеседование и оценку"
                          />
                          <IconedView
                            icon="document_scanner"
                            title="Получите ваш первоначальный отчет"
                          />
                          <IconedView
                            icon="menu_book"
                            title="Получите Информационный пакет по проекту"
                          />
                          <IconedView
                            icon="auto_awesome"
                            title="Примените ваше пособие и наблюдайте, как ваше бизнес-видение воплощается в жизнь"
                          />
                        </Box>
                      </p>

                      <Stack
                        direction={"row"}
                        spacing={1}
                        sx={{ paddingTop: "2vh" }}
                      >
                        <Icon
                          className="primary-color"
                          style={{
                            alignSelf: "center",
                            fontSize: "0.8vw",
                            marginBottom: "-0.2vh",
                          }}
                        >
                          circle
                        </Icon>
                        <p className="primary-color how-we-work-title">
                          Targeted Consultations
                        </p>
                      </Stack>
                      <p
                        className="section-description"
                        style={{ paddingLeft: "1.5vw" }}
                      >
                        Мы также готовы помочь клиентам, заинтересованным в
                        консультациях по отдельным вопросам. Пожалуйста,
                        свяжитесь с нами для получения дополнительной
                        информации.
                      </p>
                    </Box>
                    <Box>
                      <p className="section-description">
                        Для получения дополнительных подробностей, пожалуйста,
                        ознакомьтесь с руководством по{" "}
                        <a
                          href="/assets/Mulla.pdf"
                          download="Mulla Consultancy Group - Guide.pdf"
                          className="download-btn"
                        >
                          ссылке
                        </a>
                      </p>
                    </Box>
                  </Stack>
                </Grid>
                <Grid
                  item
                  alignSelf={"center"}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                  className="section-img-container"
                >
                  <img src={howWeWorkImg} className="section-img" />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      )}
    </Fragment>
  );
};
