import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { Fragment } from "react";
import abousUsImg from "../assets/about-us-light.jpg";
import "./styles.css";

export const AboutUs: React.FC = () => {
  return (
    <Fragment>
      {window.innerWidth < 1050 ? (
        <Box className="section-wrapper" id="about-us">
          <Box className="section-container">
            <Box className="section-width">
              <Stack spacing={2}>
                <img src={abousUsImg} style={{ borderRadius: "20px" }} />
                <Box>
                  <Typography
                    className="section-title"
                    sx={{ textAlign: "center", fontSize: "30px !important" }}
                  >
                    <span className="primary-color">A</span>bout Us
                  </Typography>
                </Box>
                <Box>
                  <p
                    className="section-description"
                    style={{ fontSize: "18px" }}
                  >
                    At Mulla Global Consultancy, we believe that every
                    entrepreneur is a visionary. Our role is to bring that
                    vision to life through expert business management and
                    operational consultancy services in the UAE. Whether you're
                    a local entrepreneur or an international investor, we are
                    here to help you establish and grow your business with
                    efficiency.
                  </p>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className="section-wrapper" id="about-us">
          <Box className="section-container">
            <Box className="section-width">
              <Grid container justifyContent={"space-between"}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Stack spacing={3} className="section-hover-effect">
                    <Box>
                      <Typography className="section-title">
                        <span className="primary-color">A</span>bout Us
                      </Typography>
                      <Box className="line about-us-line"></Box>
                    </Box>
                    <Box>
                      <p className="section-description">
                        At Mulla Global Consultancy, we believe that every
                        entrepreneur is a visionary. Our role is to bring that
                        vision to life through expert business management and
                        operational consultancy services in the UAE. Whether
                        you're a local entrepreneur or an international
                        investor, we are here to help you establish and grow
                        your business with efficiency.
                      </p>
                    </Box>
                  </Stack>
                </Grid>
                <Grid
                  item
                  alignSelf={"center"}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                  className="section-img-container"
                >
                  <img src={abousUsImg} className="section-img" />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      )}
    </Fragment>
  );
};
