import React, { Fragment } from "react";
import "./App.css";
import { Box, Icon, SpeedDial, SpeedDialAction } from "@mui/material";
import { Header } from "./components/header";
import { MainView } from "./components/main-view";
import { AboutUs } from "./components/about-us";
import { OurMission } from "./components/our-mission";
import { HowWeWork } from "./components/how-we-work";
import { ContactUsForm } from "./components/contact-us.form";

import { HeaderRs } from "./components-russian/header-rs";
import { MainViewRs } from "./components-russian/main-view-rs";
import { AboutUsRs } from "./components-russian/about-us-rs";
import { OurMissionRs } from "./components-russian/our-mission-rs";
import { HowWeWorkRs } from "./components-russian/how-we-work-rs";
import { ContactUsFormRs } from "./components-russian/contact-us-rs.form";
import { HeaderAr } from "./componentsa-arabic/header";
import { MainViewAr } from "./componentsa-arabic/main-view";
import { AboutUsAr } from "./componentsa-arabic/about-us";
import { HowWeWorkAr } from "./componentsa-arabic/how-we-work";
import { OurMissionAr } from "./componentsa-arabic/our-mission";
import { ContactUsFormAr } from "./componentsa-arabic/contact-us.form";

const App: React.FC = () => {
  const [selectedLanguage, setSelectedLanguage] = React.useState<string>("en");
  return (
    <Fragment>
      <Box sx={{ position: "relative" }}>
        {selectedLanguage === "en" ? (
          <Box className="hidden-overflow">
            <Header />
            <MainView />
            <AboutUs />
            <OurMission />
            <HowWeWork />
            <ContactUsForm />
          </Box>
        ) : selectedLanguage === "rs" ? (
          <Box className="hidden-overflow">
            <HeaderRs />
            <MainViewRs />
            <AboutUsRs />
            <OurMissionRs />
            <HowWeWorkRs />
            <ContactUsFormRs />
          </Box>
        ) : (
          <Box className="hidden-overflow">
            <HeaderAr />
            <MainViewAr />
            <AboutUsAr />
            <OurMissionAr />
            <HowWeWorkAr />
            <ContactUsFormAr />
          </Box>
        )}

        <Box sx={{ zIndex: 1000, position: "fixed", bottom: 20, right: 20 }}>
          <SpeedDial
            icon={<Icon>translate</Icon>}
            ariaLabel="Select Website Language"
            FabProps={{
              sx: {
                bgcolor: "#484da1", // Set the background color
                "&:hover": {
                  bgcolor: "#7577B4", // Set the hover background color
                },
              },
            }}
          >
            <SpeedDialAction
              tooltipTitle={"English"}
              icon={"EN"}
              onClick={() => {
                setSelectedLanguage("en");
              }}
            />
            <SpeedDialAction
              tooltipTitle={"Русский"}
              icon={"RU"}
              onClick={() => {
                setSelectedLanguage("rs");
              }}
            />
            <SpeedDialAction
              tooltipTitle={"العربية"}
              icon={"AR"}
              onClick={() => {
                setSelectedLanguage("ar");
              }}
            />
          </SpeedDial>
        </Box>
      </Box>
    </Fragment>
  );
};

export default App;
