import { Box, Icon, Stack } from "@mui/material";
import React, { CSSProperties, Fragment } from "react";
import "../components/styles.css";

interface IProps {
  title: string;
  icon: string;
  iconComponent?: React.ReactNode;
  textStyle?: CSSProperties;
}

export const IconedView: React.FC<IProps> = ({
  icon,
  title,
  textStyle,
  iconComponent,
}) => {
  return (
    <Fragment>
      {window.innerWidth < 451 ? (
        <Box sx={{ mt: "20px" }}>
          <Stack spacing={1} direction={"row"}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#484ca157",
                borderRadius: " 10px",
                width: "25px",
                height: "25px",
                padding: "5px",
              }}
            >
              {iconComponent ? (
                iconComponent
              ) : (
                <Icon className="primary-color" style={{ fontSize: "20px" }}>
                  {icon}
                </Icon>
              )}
            </Box>
            <Box
              alignSelf={"center"}
              sx={{ fontSize: "16px", textAlign: "start !important" }}
            >
              {title}
            </Box>
          </Stack>
        </Box>
      ) : window.innerWidth < 1050 && window.innerWidth > 450 ? (
        <Box sx={{ mt: "20px" }}>
          <Stack spacing={1} direction={"row"}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#484ca157",
                borderRadius: " 3vw",
                width: "30px",
                height: "30px",
                padding: "10px",
              }}
            >
              {iconComponent ? (
                iconComponent
              ) : (
                <Icon className="primary-color" style={{ fontSize: "30px" }}>
                  {icon}
                </Icon>
              )}
            </Box>
            <Box alignSelf={"center"} sx={textStyle}>
              {title}
            </Box>
          </Stack>
        </Box>
      ) : (
        <Box sx={{ mt: "2vh" }}>
          <Stack spacing={1} direction={"row"}>
            <Box className="point-icon-wrapper">
              {iconComponent ? (
                iconComponent
              ) : (
                <Icon className="primary-color point-icon">{icon}</Icon>
              )}
            </Box>
            <Box alignSelf={"center"} sx={textStyle}>
              {title}
            </Box>
          </Stack>
        </Box>
      )}
    </Fragment>
  );
};
